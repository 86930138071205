<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <h6 class="mb-0">Danh sách user</h6>
                <b-row style="float: right">
                  <b-button variant="outline-primary" href="#/user/add-user"
                    >Thêm mới user</b-button
                  >
                </b-row>
              </template>
              <div class="filter-transaction">
                <b-row>
                  <b-col>
                    <b-form-group label="Email">
                      <b-form-input
                        v-model="input.search"
                        placeholder="Tên"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="" style="margin-top: 30px">
                      <b-button
                        type="submit"
                        variant="primary"
                        v-on:click="listAll()"
                        >Tìm kiếm
                      </b-button>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
              <b-table
                table-class="datatable-table"
                hover
                tbody-tr-class="datatable-row"
                thead-tr-class="datatable-row"
                thead-class="datatable-head"
                details-td-class="datatable-cell"
                :items="items"
                :fields="fields"
              >
                <template #cell(options)="data">
                  <b-button
                    class="mr-2"
                    variant="outline-primary"
                    @click="linkDetail(data.item.id)"
                    size="sm"
                    >Chỉnh sửa
                  </b-button>
                  &nbsp;
                  <b-button
                    class="mr-2"
                    variant="outline-danger"
                    @click="deleteItem(data.item.id)"
                    size="sm"
                    >Xoá
                  </b-button>
                </template>
              </b-table>
              <div class="mt-3">
                <b-pagination-nav
                  v-model="view.currentPage"
                  :link-gen="createLinkPage"
                  :number-of-pages="view.paginate.totalPage"
                  use-router
                  first-number
                  last-number
                  align="right"
                ></b-pagination-nav>
              </div>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
// import filterTransaction from "@/view/pages/transaction/items/filterTransaction.vue";
import Common from "@/core/mixins/common";

const CmsRepository = RepositoryFactory.get("cms");
export default {
  mixins: [Common],
  data() {
    return {
      input: {
        search: null,
      },
      filter: {},
      fields: [
        { id: "Id" },
        { email: "Email" },
        { name: "Tên người dùng" },
        { options: "Tùy chọn" },
      ],
      items: [],
      currentPage: 1,
      view: {
        paginate: {
          limit: 25,
          page: 1,
          totalPage: 1,
          total: 0,
          skip: 0,
        },
      },
    };
  },
  components: {
    // filterTransaction
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Danh sách user", route: "list-user" },
    ]);
    this.filter = this.$route.query;
  },
  methods: {
    listAll() {
      let params = {
        page: this.view.currentPage,
      };
      if (this.input.search !== null && this.input.search !== "") {
        params.search = this.input.search;
      }
      this.$bus.$emit("show-loading", true);
      CmsRepository.listUser(params)
        .then((response) => {
          if (response.data.error_code) {
            alert(response.data.message);
          } else {
            this.items = response.data.data.data;
            this.view.paginate.total = response.data.data.total;
            this.view.paginate.totalPage = response.data.data.last_page;
            this.view.currentPage = response.data.data.current_page;
          }
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          alert("Có lỗi xảy ra");
          this.$bus.$emit("show-loading", false);
        });
    },
    linkDetail(id) {
      // this.$router.push("/detail-user?id=" + id);
      //   this.$router.push({path: this.$route.fullPath, query: {page: this.currentPage} });
      return this.$router.push({ name: "detail-user", query: { id: id } });
    },
    deleteItem(id) {
      if (!confirm("Bạn có chắc muốn xóa?")) {
        return;
      }
      this.$bus.$emit("show-loading", true);
      CmsRepository.deleteUser(id)
        .then((response) => {
          if (response.data.error_code) {
            alert(response.data.message);
          } else {
            this.listAll();
          }
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          alert("Có lỗi xảy ra");
          this.$bus.$emit("show-loading", false);
        });
    },
  },
  watch: {
    $route: "getList",
    "view.currentPage"() {
      this.listAll();
    },
  },
};
</script>
